.leaveBtnBg {
  background: rgba(244, 67, 54, 0.3);
}
/* Base styles for the slider */
.gainSlider {
  width: 100px;
  margin:10px; /* Adjusted to full width for better control */

  -webkit-appearance: none; /* Removes default styling */
  appearance: none;
  height: 2px; /* Makes the track taller */
  background: var(--Mako); /* Sets the track background color */
  outline: none; /* Removes the default outline */
  opacity: 0.7; /* Makes the slider slightly transparent */
  transition: opacity 0.2s; /* Smooth transition for hover effect */
  align-self: center;
  
}

/* Styles for the thumb of the slider */
.gainSlider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Necessary to remove default styling */
  appearance: none;
  width: 15px; /* Width of the thumb */
  height: 15px; /* Height of the thumb, matching track height */
  background: var(--Swiss-Coffee); /* Red color for the thumb */
  cursor: pointer; /* Cursor appears as pointer when over the thumb */
  border-radius: 100px; /* Rounded corners for the thumb */
}



.gainSlider::-moz-range-thumb {
  width: 50px;
  height: 15px;
  background: #f44336;
  cursor: pointer;
  border-radius: 5px;
}

.gainSlider::-ms-thumb {
  width: 50px;
  height: 15px;
  background: #f44336;
  cursor: pointer;
  border-radius: 5px;
}

/* Optional: Styles for the track of the slider for Mozilla */
.gainSlider::-moz-range-track {
  background: black;
  height: 25px;
}

/* Optional: Styles for the track of the slider for IE */
.gainSlider::-ms-track {
  background: black;
  height: 25px;
  border-color: transparent; /* Removes default border */
  color: transparent; /* Required for proper track styling in IE */
}

.gainSlider:hover{
  opacity: 1;
}
