@tailwind base;
@tailwind components;
@tailwind utilities;

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

// html,
// body,
// div,
// span,
// applet,
// object,
// iframe,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// blockquote,
// pre,
// a,
// abbr,
// acronym,
// address,
// big,
// cite,
// code,
// del,
// dfn,
// em,
// img,
// ins,
// kbd,
// q,
// s,
// samp,
// small,
// strike,
// strong,
// sub,
// sup,
// tt,
// var,
// b,
// u,
// i,
// center,
// dl,
// dt,
// dd,
// ol,
// ul,
// li,
// fieldset,
// form,
// label,
// legend,
// table,
// caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
// article,
// aside,
// canvas,
// details,
// embed,
// figure,
// figcaption,
// footer,
// header,
// hgroup,
// menu,
// nav,
// output,
// ruby,
// section,
// summary,
// time,
// mark,
// audio,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// menu,
// nav,
// section {
//   display: block;
// }
// body {
//   line-height: 1;
// }
// ol,
// ul {
//   list-style: none;
// }
// blockquote,
// q {
//   quotes: none;
// }
// blockquote:before,
// blockquote:after,
// q:before,
// q:after {
//   content: '';
//   content: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

// app styles

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --Orange: #FB6520;
  --Mine-Shaft: #202020;
  --Tuna: #313139;
  --Mako: #44444D;
  --Gun-Powder: #48485C;
  --Manatee: #8A8A96;
  --Swiss-Coffee: #DDD8D8;
  --Saffron: #FABC35;
  --Jungle-Green: #2DBF81;
  --Flamingo: #F44336;
  --default-text-color: var(--Swiss-Coffee); /* Default text color if not specified */
  font-family: Arial, Helvetica, sans-serif;
}


@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  // color: rgb(var(--foreground-rgb));
  color: white;
  background-image: url('../../public/backgrounds/background.png');
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  height: fit-content;

}
.warning-message {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.4px;
  letter-spacing: 0.5px; /* Additional letter spacing */
  color: var(--subtitle-text-color); /* Using the default subtitle text color */
  border: 1px solid var(--Flamingo);
  padding: 10px;
  border-radius:10px;
  background-color: var(--Mine-Shaft);
  margin-top: 20px;
}
// Custom class for font types
.subtitle-rf-16 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.4px;
  letter-spacing: 0.5px; /* Additional letter spacing */
  color: var(--subtitle-text-color); /* Using the default subtitle text color */
}

.subtitle-20-reg {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 23px; /* 115% */
  color: var(--subtitle-text-color); /* Text color controlled via CSS variable */
}

.title-36-reg {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 40px; /* 115% */
  color: var(--subtitle-text-color); /* Text color controlled via CSS variable */
}

.body-rf-12 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: normal; /* Default line height */
  color: var(--body-text-color); /* Text color controlled via CSS variable */
}

.body-14-rf {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: normal; /* Default line height */
  color: var(--body-14-rf-text-color); /* Text color controlled via CSS variable */
}

.body-15-regular {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 20px; /* 133.333% */
  color: var(--body-15-text-color); /* Text color controlled via CSS variable */
}

.body-large-17-regular {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 20px; /* 117.647% */
  color: var(--body-large-text-color); /* Text color controlled via CSS variable */
}

.body-large-17-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: 20px; /* 117.647% */
  color: var(--body-large-bold-text-color); /* Text color controlled via CSS variable */
}

.title-20-rf {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: normal; /* Default line height */
  color: var(--title-20-rf-text-color); /* Text color controlled via CSS variable */
}

.title-20-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: 23px; /* 115% */
  color: var(--title-20-bold-text-color); /* Text color controlled via CSS variable */
}

.title-24-rf {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: normal;
  color: var(--title-24-rf-text-color); /* Text color controlled via CSS variable */
}

.title-large-28-regular {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 32px; /* 114.286% */
  color: var(--title-large-text-color); /* Text color controlled via CSS variable */
}

.title-28-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: 32px; /* 114.286% */
  color: var(--title-text-color); /* Text color controlled via CSS variable */
}

.title-30-rf {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: normal; /* Default line height */
  color: var(--title-30-rf-text-color); /* Text color controlled via CSS variable */
}

.title-session-settings {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 23px; /* 82.143% */
  color: var(--title-session-settings-text-color); /* Text color controlled via CSS variable */
}

.caption-15-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: 20px; /* 133.333% */
  color: var(--caption-text-color); /* Text color controlled via CSS variable */
}

.subtitle-session {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: 23px; /* 143.75% */
  color: var(--subtitle-session-text-color); /* Text color controlled via CSS variable */
}

.reg-title-24 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 33px; /* 137.5% */
  color: var(--reg-title-24-text-color); /* Text color controlled via CSS variable */
}

.reg-title-28 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 33px; /* 117.857% */
  color: var(--reg-title-text-color); /* Text color controlled via CSS variable */
}

.footnote-10-rf {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: normal; /* Default line height */
  color: var(--footnote-10-rf-text-color); /* Text color controlled via CSS variable */
}

.error-message-below-text-fields {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 20px; /* 200% */
  color: var(--error-message-text-color); /* Text color controlled via CSS variable */
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; /* Ensure it's above other content */
  background-color: rgba(0, 0, 0, 0); /* Optional: for dimmed background */
}

.footnotes-15-regular {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400; /* Regular */
  line-height: 20px; /* 133.333% */
  color: var(--footnotes-text-color); /* Text color controlled via CSS variable */
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional-text {
  margin-top: 30px; /* Adjust as needed for space below the modal */
  text-align: center;
}

.email-input::placeholder {
  font-style: italic; /* Make the placeholder text italicized */
  color: rgba(221, 216, 216, 0.2); /* Swiss Coffee with 50% transparency */
}

/* For full browser support, include the following vendor-specific pseudo-elements */
.email-input:-ms-input-placeholder {
  font-style: italic;
  color: rgba(221, 216, 216, 0.2);
}

.email-input::-ms-input-placeholder {
  font-style: italic;
  color: rgba(221, 216, 216, 0.2);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.2); /* White color with 50% opacity */
  font-size: 17px;
}

.login-container {
  position: relative;
  /* Make sure to give it enough padding or margin on the right to accommodate the policy menu */
}

.password-policy-container {
  position: absolute;
  right: -70%;      /* Adjust as needed */
  top: 90%;      /* Adjust as needed, this will center it vertically */
  transform: translateY(-20%); /* Centers the div based on its own height */
  width: 300px;  /* Width of the password policy menu */
  background: #fff; /* Background color for the policy menu */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: to make it pop out a bit */
  border-radius: 4px; /* Optional: if you want rounded corners */
  z-index: 100; /* Ensure it's above other content */
}

.success-message {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: var(--Swiss-Coffee); /* Text color using Swiss Coffee */
  background-color: var(--Jungle-Green); /* Background color using Jungle Green */
  padding: 10px; /* Added for spacing, adjust as needed */
  border-radius: 4px; /* Rounded corners, adjust as needed */
  margin-top: 10px; /* Margin for spacing, adjust as needed */
  font-weight: bold; /* Bold text, adjust as needed */
  text-align: center; /* Center the text */
}
.video-container{
  margin-top: 20px;
  height: 150px;
}

.loading-dots {
  display: inline-block;
  margin-left: 5px;
  font-size: 20px;
  line-height: 20px;
  vertical-align: top;
  animation: dots 1.5s infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
  }
}

.focused {
  border: 1px solid var(--Swiss-Coffee);
}
